import { FieldError } from "react-hook-form"

const renderWarnings = (a: any[] = []) => (
    a.length > 0 && a.map((warning, idx) => (
        <span key={warning + "-" + idx} className="text-orange-400">{warning}</span>
    ))
)
  
const renderErrors = (error: FieldError | undefined) => (
    error && <span className="text-red-600 flex-[50%]">{error.message}</span>
)


export{
    renderErrors,
    renderWarnings
}