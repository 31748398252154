import { epiApiGet } from '@/api/content-delivery'
import { CartLineItemModel, OrderModel } from '@/api/types/content-delivery-types'
import LoadingSpinner from '@/components/shared/loading-spinner'
import OrderSummary from '@/components/shared/order-summary/order-summary'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import { formatDateShort } from '@/lib/currency/formatting'
import { SYSTEMKEYWORD_DUMMY, SYSTEMKEYWORD_PAYFABRIC } from '@/stores/checkout-store'
import { useGlobalSettings } from '@/stores/global-settings'
import { CommerceBasePageProps } from '@/types/shared'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AddressDisplay } from '../checkout/components/address-display'
import { CartLineItemReadOnly } from '../checkout/components/cart-line-item-read-only'
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET } from '@/lib/constants'
import { checkForNoShipping } from '@/components/shared/shipping-options/shipping'
import withHydration from '@/lib/withHydration'

export type OrderDetailsPageProps = {
  model: {
    pageUrl: string
    ShippingMethodName: string
  }
} & CommerceBasePageProps

const OrderDetailsPage = (props: Readonly<OrderDetailsPageProps>) => {
  const { t } = useTranslation()
  const { pageTitle, pageDescription, topContentArea, bottomContentArea } = props.model
  const [searchParams, _] = useSearchParams()
  const [order, setOrder] = useState<OrderModel>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const orderNumber = searchParams.get('orderNumber')
  const globalSettings = useGlobalSettings((s) => s.globalSettings)
  const navigate = useNavigate()

  const renderShippingSection = () => {
    if (!order || order.shipments?.length == 0) return <></>
    return (
      <>
        <div className="mt-2 pb-2 font-bold text-grey-950">{t('OrderDetails.ShippingAddress')}</div>
        <section className="mt-3 flex flex-auto flex-row border-b border-solid border-grey-200 pb-6">
          <div className="w-1/2">
            <div>
              <div className="pb-2 text-[14px] font-bold text-grey-950">
                {t('OrderConfirmation.ShippingDetailsTitle')}
              </div>
              <AddressDisplay addressModel={order.shipments[0].shippingAddress} />
            </div>
          </div>
          <div className="w-1/2">
            <div>
              <div className="pb-2 text-[14px] font-bold text-grey-950">
                {t('OrderConfirmation.ShippingMethodTitle')}
              </div>
              <div>{checkForNoShipping({name: order?.shippingMethodName, localizedValue: t("Common.NoShipping")})}</div>
            </div>
          </div>
        </section>
      </>
    )
  }

  const renderPaymentSection = () => {
    if (!order || order.payments?.length == 0) return <></>
    const paymentMethod = order.payments.find((p) => p.systemKeyword !== SYSTEMKEYWORD_DUMMY)
    const purchaseOrderNumber = paymentMethod?.extendedProperties?.find(x => x.name.includes("po_number"))?.value ?? "";
    let paymentMethodInfo = t('OrderConfirmation.PurchaseOrder')
    if (paymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYFABRIC) {
      const cardType = paymentMethod?.extendedProperties?.find((kv) => kv.name === 'pf_cardtype')?.value ?? 'Credit'
      const lastFour = paymentMethod?.extendedProperties?.find((kv) => kv.name === 'pf_lastfour')?.value ?? '####'
      paymentMethodInfo = cardType + ' ****' + lastFour
    }
    return (
      <>
        <div className="mt-2 pb-2 font-bold text-grey-950">{t('OrderDetails.PaymentTitle')}</div>
        <section className="mt-3 flex flex-auto flex-row pb-6">
        <div className="w-1/2">
            <div>
              <div className="pb-2 font-bold text-grey-950">{t('OrderDetails.BilledTo')}</div>
              <AddressDisplay addressModel={order.payments[0].billingAddress} />
            </div>
          </div>
          <div className="w-1/2">
            <div className="pb-2 text-[14px] font-bold text-grey-950"> {t('OrderDetails.PayInFullWith')}</div>
            <div>{paymentMethodInfo} {purchaseOrderNumber ? ` - ${purchaseOrderNumber}` : ''}</div>
          </div>
        </section>
      </>
    )
  }

  const renderCartItems = () => {
    const lineItems = order?.shipments.flatMap((s) => s.lineItems)
    return (
      <div className="flex flex-col gap-4 overflow-y-auto min-h-[300px] max-h-[300px]">
        {lineItems?.map((lineItem: CartLineItemModel) => (
          <CartLineItemReadOnly
            key={lineItem.id}
            cartLineModel={lineItem}
            currency={order?.currency}
            calculateCartLine={() => lineItem.placedPrice * lineItem.quantity}
          />
        ))}
      </div>
    )
  }

  useEffect(() => {
    setIsLoading(true)
    const getOrder = async () => {
      if (!orderNumber) return
      const order = await epiApiGet<OrderModel>(`${props.model.pageUrl}GetOrder?orderNumber=${orderNumber}`)
      if (order && order.error === 404) {
        const errorPageUrl = globalSettings?.errorPages?.['404']
        errorPageUrl && navigate(errorPageUrl)
        setIsLoading(false)
        return
      }
      setOrder(order)
      setIsLoading(false)
    }
    getOrder()
  }, [orderNumber])

  return (
      <div className="container mt-5 flex flex-col py-0 font-mulish">
       {isLoading ?
          <div className="col-span-1 mt-3 flex h-[70vh] w-full items-center justify-center sm:col-span-2">
            <LoadingSpinner className="h-12 w-12 text-grey-light" />
          </div>
          :
          <div className="flex flex-col">
            <div className="flex w-full flex-col gap-[12px] pb-[10px]">
              <h1 className="text-2xl font-bold text-grey-dark lg:text-4xl">{pageTitle}</h1>
              <span>{pageDescription}</span>
            </div>
            {RenderContentArea(topContentArea)}
            <div className="flex grid-cols-8 flex-col-reverse gap-7 md:grid md:flex-col">
              <div className="flex-1 md:col-span-5">
                <section className="border-b border-solid border-grey-200 pb-16 pt-4">
                  <div className="inline-block bg-grey-secondary-light px-2 py-1 text-[12px] font-bold uppercase tracking-[1px] text-grey-dark">
                    {t(`OrderDetails.Status.${order?.status}`)}
                  </div>
                  <div className="pt-1 text-3xl font-bold text-grey-950">
                    {t('OrderConfirmation.OrderNumber', { 0: order?.orderNumber})}
                  </div>
                  <div className="text-[20px] text-grey-950">{formatDateShort(order?.orderDate?.toString() ?? "")}</div>
                </section>

                {renderShippingSection()}
                {renderPaymentSection()}

              </div>
              <div className="flex-auto md:col-span-3">

                <div className="h-auto pt-0 px-0 lg:px-[36px] lg:pt-2 text-grey-dark">
                  {renderCartItems()}
                  <OrderSummary
                    isLoading={isLoading}
                    hasShipping={true}
                    onPage="checkout"
                    cartInfo={{
                      market: order?.market ?? DEFAULT_MARKET,
                      currency: order?.currency ?? DEFAULT_CURRENCY_CODE,
                    }}
                    cartTotals={order?.totals}
                    localizations={{
                      cartSummaryTitle: t('OrderConfirmation.OrderSummaryTitle'),
                    }}
                  />

              </div>
            </div>
          </div>
          <div className="mb-3 mt-3">{RenderContentArea(bottomContentArea)}</div>
        </div>
        }
    </div>
  )
}

export default withHydration(OrderDetailsPage)
