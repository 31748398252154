import '@/components/shared/commerce/styles/forms.scss'
import { beginCheckoutGTM } from '@/components/shared/analytics/gtm-manager'
import LoadingSpinner from '@/components/shared/loading-spinner'
import OrderSummary from '@/components/shared/order-summary/order-summary'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import { useCartStore } from '@/stores/useCartStore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useStep } from 'usehooks-ts'
import { CartLineItemReadOnly } from './components/cart-line-item-read-only'
import BillingShippingStep from './components/checkout-steps/billing-shipping-step'
import { ReviewStep } from './components/checkout-steps/review-step'
import ShippingMethodStep from './components/checkout-steps/shipping-method-step'
import { TaxExemptionCheckbox } from './components/tax-exemption-checkbox'
import { CheckoutPageProps } from './types/checkout-types'
import { useShippingSameAsBilling } from '@/stores/useShippingSameAsBilling'
import { validateAddress } from '@/api/validation'
import { useGlobalSettings } from '@/stores/global-settings'
import SharedLink from '@/components/shared/buttons/shared-link'
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET } from '@/lib/constants'
import { useUserStore } from '@/stores/useUserStore'
import withHydration from '@/lib/withHydration'

const CheckoutPage = (props: Readonly<CheckoutPageProps>) => {
  const { user } = useUserStore()
  const {
    cartData,
    isLoadingCartTotals,
    validatedCartData,
    updateCartShippingAddress,
    validateCart,
    calculateCartLine,
    loadUserCart
  } = useCartStore()
  const globalSettings = useGlobalSettings((s) => s.globalSettings)
  const { t } = useTranslation()
  const { shippingSameAsBilling, setShippingSameAsBilling, setDisabled } = useShippingSameAsBilling()
  const [stepOneFormSubmitting, setStepOneFormSubmitting] = useState<boolean>(false)
  const [stepTwoFormSubmitting, setStepTwoFormSubmitting] = useState<boolean>(false)
  const [stepThreeFormSubmitting, setStepThreeFormSubmitting] = useState<boolean>(false)
  const [triggerBeginCheckoutGMT, setTriggerBeginCheckoutGMT] = useState<boolean>(false)

  const [isTaxExempt, setIsTaxExempt] = useState<boolean>()
  const [updateTotals, setUpdateTotals] = useState<boolean>(false)

  const noShippingShippingMethodId = validatedCartData?.availableShippingMethods?.find((s) => s.displayName === 'NO_SHIPPING')?.id;
  const hasShipping = !!cartData?.fullCart?.shipments?.find((s) => !!s.shippingAddress) &&
    !cartData?.fullCart?.shipments.find((s) => s.shippingMethodId === '00000000-0000-0000-0000-000000000000' ||
      s.shippingMethodId === noShippingShippingMethodId);
  const { pageTitle, pageDescription, topContentArea, bottomContentArea, orderSummaryContentArea, localizations,  } =
    props.model

  const [currentStep, helpers] = useStep(3)
  const { setStep } = helpers

  useEffect(() => {
    loadUserCart()
    setStep(1)
  }, [])

  useEffect(() => {
    if (!stepOneFormSubmitting && !stepTwoFormSubmitting && !stepThreeFormSubmitting) {
      validateCart()
    }
  }, [stepOneFormSubmitting, stepTwoFormSubmitting, stepThreeFormSubmitting])

  useEffect(() => {
    if (updateTotals === true) {
      validateCart()
      setUpdateTotals(false)
    }
  }, [updateTotals])

  const styleStep1 = currentStep === 1 || currentStep === 2 || currentStep === 3
  const styleStep2 = currentStep === 2 || currentStep === 3
  const styleStep3 = currentStep === 3
  const step1TabDisabled = currentStep === 1
  const step2TabDisabled = step1TabDisabled || currentStep === 2
  const step3TabDisabled = step2TabDisabled || currentStep === 3

  useEffect(() => {
    if (triggerBeginCheckoutGMT) return
    beginCheckoutGTM(cartData)
    setTriggerBeginCheckoutGMT(true)
  }, [cartData?.cartLines?.length, validatedCartData?.totals.total])

  const renderPageHeader = () => (
    <>
      {pageTitle ||
        (pageDescription && (
          <div className="flex w-full flex-col gap-[12px] pb-[10px]">
            {pageTitle && <h1 className="text-2xl font-bold text-grey-dark lg:text-4xl">{pageTitle}</h1>}
            {pageDescription && <span>{pageDescription}</span>}
          </div>
        ))}
    </>
  )

  const submitStepOne = async () => {
    const cantSave = (!validateAddress(cartData?.fullCart?.payments[0]?.billingAddress?.line1 ?? "", globalSettings?.postOfficeRegex ?? "") && shippingSameAsBilling)
    const invalidShippingAddress = cartData?.fullCart?.shipments[0]?.shippingAddress === null || (cartData?.fullCart?.shipments[0]?.shippingAddress?.line1 ?? "") === ""
    const invalidBillingAddress = cartData?.fullCart?.payments[0]?.billingAddress === null || (cartData?.fullCart?.payments[0]?.billingAddress?.line1 ?? "") === ""
    if (cantSave)
    {
      setShippingSameAsBilling(false)
      setDisabled(true)
      toast(
        <div className="flex flex-col gap-2">
          <span className="text-red-600">{t("Checkout.ShippingAddress")}</span>
          <span className="text-black">{t("Checkout.ShippingSameAsBillingPoBox")}</span>
        </div>,
        { position: 'top-right' },
      )
      return;
    }

    if (!validateAddress(cartData?.fullCart?.shipments[0]?.shippingAddress?.line1 ?? "", globalSettings?.postOfficeRegex ?? "")){
      toast(
        <div className="flex flex-col gap-2">
          <span className="text-red-600">{t("Checkout.ShippingAddress")}</span>
          <span className="text-black">{t("Checkout.ShippingSameAsBillingPoBox")}</span>
        </div>,
        { position: 'top-right' },
      )
      return;
    }
    if (invalidBillingAddress) 
    {
      toast(
        <div className="flex flex-col gap-2">
          <span className="text-red-600">{t("Checkout.BillingAddress")}</span>
          <span className="text-black">{t("Checkout.BillingAddressRequiredMessage")}</span>
        </div>,
        { position: 'top-right' },
      )
      return
    }
  
    if(invalidShippingAddress && !shippingSameAsBilling) 
    {
      toast(
        <div className="flex flex-col gap-2">
          <span className="text-red-600">{t("Checkout.ShippingAddress")}</span>
          <span className="text-black">{t("Checkout.ShippingAddressRequiredMessage")}</span>
        </div>,
        { position: 'top-right' },
      )
      return
    }
    setStepOneFormSubmitting(true)
    const hasPayments = cartData?.fullCart?.payments.some((x) => x.billingAddress !== null)
    if (hasPayments) {
      await updateCartShippingAddress({
        billingAddress: cartData?.fullCart?.payments[0].billingAddress ?? null,
        shippingAddress: null,
        isShippingSameAsBilling: shippingSameAsBilling,
        taxExemption: isTaxExempt
      })
    }
    if (!hasPayments) {
      toast(
        <div className="flex flex-col gap-2">
          <span className="text-red-600">{t("Checkout.Warning")}</span>
          <span className="text-black">{t("Checkout.CreateBillingAddress")}</span>
        </div>,
        { position: 'top-right' },
      )
      setStepOneFormSubmitting(false)
      return
    }

    setStep(2)
    setStepOneFormSubmitting(false)
  }

  return (
    <div className="container flex flex-col py-0 font-mulish">
      <div className="flex flex-col">
        <div className="flex grid-cols-8 flex-col gap-7 lg:grid lg:gap-[36px]">
          <div className="mt-8 mb-8 flex-1 lg:col-span-5">
            {renderPageHeader()}
            {topContentArea && topContentArea.length > 0 && (
              <div className="reduced-font mb-5">{RenderContentArea(topContentArea)}</div>
            )}
            <div className="flex flex-col lg:mb-[60px]">
              <div className="flex flex-1 flex-row items-center pb-[30px]">
                <div className="flex flex-col gap-[10px] max-h-[51px] max-w-[51px] w-full">
                  <button onClick={() => setStep(1)} className="flex-1" disabled={step1TabDisabled}>
                    <svg className="relative z-[55] max-h-[51px] max-w-[51px]">
                      <circle
                        className={`${styleStep1 ? 'fill-dark-blue-primary' : 'stroke-grey-disabled fill-white'}`}
                        cx="26"
                        cy="26"
                        r="24"
                        fill="none"
                        strokeWidth="2"
                        shapeRendering="geometricPrecision"
                      />
                      <text
                        className=" fill-white p-1"
                        x="26"
                        y="27.5"
                        fontSize={24}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        1
                      </text>
                    </svg>
                  </button>
                  <div className="absolute mt-[55px] hidden lg:block">
                    <span className="relative text-sm font-bold text-black">{t('Checkout.ShippingStep')}</span>
                  </div>
                </div>

                <svg className="relative z-[1] w-full lg:h-auto">
                    <line x1="0" y1="75" x2="100%" y2="75" className={`${styleStep2 ? 'stroke-dark-blue-primary' : 'stroke-grey-disabled'}`} strokeWidth="2" />
                </svg>

                <div className="flex flex-col gap-[10px] max-h-[51px] max-w-[51px] w-full">
                  <button onClick={() => setStep(2)} className="flex-1" disabled={step2TabDisabled}>
                    <svg style={{ marginLeft: '-1px' }} className="relative z-[55] max-h-[51px] max-w-[51px]">
                      <circle
                        className={`${styleStep2 ? 'fill-dark-blue-primary' : 'stroke-grey-disabled fill-white'}`}
                        cx="26"
                        cy="26"
                        r="24"
                        fill="none"
                        strokeWidth="2"
                        shapeRendering="geometricPrecision"
                      />
                      <text
                        className={`${styleStep2 ? 'fill-white' : 'fill-black'} p-1`}
                        x="26"
                        y="27.5"
                        fontSize={24}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        2
                      </text>
                    </svg>
                  </button>
                  <div className="absolute ml-[-30px] mt-[55px] hidden lg:block">
                    <span className="relative text-sm font-bold text-black">{t('Checkout.ShippingMethodStep')}</span>
                  </div>
                </div>

                <svg className="relative z-[1] w-full lg:h-auto">
                    <line x1="0" y1="75" x2="100%" y2="75" className={`${styleStep3 ? 'stroke-dark-blue-primary' : 'stroke-grey-disabled'}`} strokeWidth="2" />
                </svg>

                <div className="relative flex flex-col gap-[10px] max-h-[51px] max-w-[51px] w-full">
                  <button onClick={() => setStep(3)} className="flex-1" disabled={step3TabDisabled}>
                    <svg style={{ marginLeft: '-1px' }} className="relative z-[55] max-h-[51px] max-w-[51px]">
                      <circle
                        className={`${styleStep3 ? 'fill-dark-blue-primary' : 'stroke-grey-disabled fill-white'}`}
                        cx="26"
                        cy="26"
                        r="24"
                        fill="none"
                        strokeWidth="2"
                        shapeRendering="geometricPrecision"
                      />
                      <text
                        className={`${styleStep3 ? 'fill-white' : 'fill-black'} p-1`}
                        x="26"
                        y="27.5"
                        fontSize={24}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        3
                      </text>
                    </svg>
                  </button>
                  <div className="absolute right-0 mt-[55px] hidden whitespace-nowrap lg:block">
                    <span className="relative text-sm font-bold text-black">{t('Checkout.ReviewAndPaymentStep')}</span>
                  </div>
                </div>
              </div>
            </div>
            {currentStep === 1 && (
              <>
                {stepOneFormSubmitting && (
                  <div className="flex w-full items-center justify-center p-2 sm:col-span-2">
                    <LoadingSpinner className="h-5 w-5 text-grey-light" />
                  </div>
                )}

                <div>
                  <SharedLink className="p-[0px]" href={globalSettings?.cartPageUrl} style="text">
                      &larr; {t('CheckoutBillingShippingStep.BackButton')}
                  </SharedLink>
                  <h2 className="text-3xl font-bold text-black">{t('Checkout.ShippingStep')}</h2>
                </div>

                <BillingShippingStep
                  localizations={localizations}
                  setStepOneFormSubmitting={setStepOneFormSubmitting}
                  setStep={setStep}
                />
              </>
            )}
            {currentStep === 2 && (
              <>
                {stepTwoFormSubmitting && (
                  <div className="flex w-full items-center justify-center p-2 sm:col-span-2">
                    <LoadingSpinner className="h-5 w-5 text-grey-light" />
                  </div>
                )}
                <div className="mb-[60px]">
                  <SharedLink className="p-[0px]" href={globalSettings?.cartPageUrl} style="text">
                      &larr; {t('CheckoutBillingShippingStep.BackButton')}
                  </SharedLink> 
                  <h2 className="text-3xl font-bold text-black">{t('Checkout.ShippingMethodStep')}</h2>
                </div>

                <ShippingMethodStep
                  setUpdateTotals={setUpdateTotals}
                  setStepTwoFormSubmitting={setStepTwoFormSubmitting}
                  setStep={setStep}
                />
              </>
            )}

            {currentStep === 3 && (
              <>
                {stepThreeFormSubmitting && (
                  <div className="flex w-full items-center justify-center p-2 sm:col-span-2">
                    <LoadingSpinner className="h-5 w-5 text-grey-light" />
                  </div>
                )}
                <div className="mb-[60px]">
                  <SharedLink className="p-[0px]" href={globalSettings?.cartPageUrl} style="text">
                      &larr; {t('CheckoutBillingShippingStep.BackButton')}
                  </SharedLink>
                  <h2 className="text-3xl font-bold text-black">{t('Checkout.ReviewAndPaymentStep')}</h2>
                </div>
                <ReviewStep setStep={setStep} setStepThreeFormSubmitting={setStepThreeFormSubmitting}></ReviewStep>
              </>
            )}
            {bottomContentArea && bottomContentArea.length > 0 && (
              <div className="reduced-font my-3">{RenderContentArea(bottomContentArea)}</div>
            )}
          </div>

          <div className="rounded-b-md -mx-[1.125rem] flex-auto bg-grey-secondary-light text-grey-dark lg:col-span-3 lg:mx-0">
            {currentStep !== 3 && (
              <div className="flex h-auto flex-col gap-3 px-[1.125rem] pt-0 lg:px-[36px] lg:pt-8">
                <OrderSummary
                  isLoading={isLoadingCartTotals}
                  hasShipping={hasShipping}
                  onPage="checkout"
                  cartInfo={{
                    market: user?.authInfo?.market ?? DEFAULT_MARKET,
                    currency: user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE,
                  }}
                  cartTotals={validatedCartData?.totals}
                  localizations={{
                    cartSummaryTitle: localizations.cartSummaryTitle,
                  }}
                />
                <TaxExemptionCheckbox 
                  setIsTaxExempt={setIsTaxExempt} 
                  forceDisable={currentStep !== 1} />
                  
                {currentStep === 1 && (
                  <div className="CommerceForms flex flex-col gap-3">
                    <button
                      className={`Button ${{ stepOneFormSubmitting } ? 'disabled' : ''}`}
                      disabled={isLoadingCartTotals || stepOneFormSubmitting}
                      onClick={submitStepOne}
                      type="button"
                      title="Submit"
                      value="Submission"
                    >
                      
                      {t("CheckoutBillingShippingStep.ContinueToShipping")}
                    </button>
                  </div>
                )}
                {currentStep === 2 && (
                  <div className="CommerceForms flex flex-col gap-3">
                    <button
                      className={`Button ${{ stepTwoFormSubmitting } ? 'disabled' : ''}`}
                      disabled={isLoadingCartTotals || stepTwoFormSubmitting}
                      form={'step-two-form'}
                      type="submit"
                      title="Submit"
                      value="Submission"
                    >
                       {t("CheckoutShippingMethodStep.ContinueToReview")}
                    </button>
                  </div>
                )}

                <div className="reduced-font my-3">{RenderContentArea(orderSummaryContentArea)}</div>
              </div>
            )}

            {currentStep === 3 && (
              <div className="flex h-auto flex-col gap-3 px-[1.125rem] pt-8 lg:px-[36px]">
                <div className="flex flex-col gap-4 overflow-y-auto lg:max-h-[300px] lg:min-h-[300px]">
                  {cartData?.cartLines?.map((product) => (
                    <CartLineItemReadOnly
                      key={product.id}
                      cartLineModel={product}
                      currency={cartData.cartInfo?.currency ?? DEFAULT_CURRENCY_CODE}
                      calculateCartLine={calculateCartLine}
                    />
                  ))}
                </div>

                <OrderSummary
                  isLoading={isLoadingCartTotals}
                  hasShipping={hasShipping}
                  onPage="checkout"
                  cartInfo={{
                    market: user?.authInfo?.market ?? DEFAULT_MARKET,
                    currency: user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE,
                  }}
                  cartTotals={validatedCartData?.totals}
                  localizations={{
                    cartSummaryTitle: localizations.cartSummaryTitle,
                  }}
                />

                <div className="CommerceForms flex flex-col gap-3">
                  <button
                    className={`Button ${{ stepThreeFormSubmitting } ? 'disabled' : ''}`}
                    disabled={isLoadingCartTotals || stepThreeFormSubmitting}
                    form={'step-three-form'}
                    type="submit"
                    title="Submit"
                    value="Submission"
                  >
                    {t('CheckoutReviewStep.PlaceOrder')}
                  </button>
                </div>

                <div className="reduced-font my-3">{RenderContentArea(orderSummaryContentArea)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default withHydration(CheckoutPage)
