import { currencyToLocale } from '@/components/shared/price/types/price-types'
import Price from '@/components/shared/price/price'
import { OrderSummaryProps } from './types/order-summary-types'
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants'
import LoadingSpinner from '../loading-spinner'
import { useTranslation } from 'react-i18next'

const OrderSummary: React.FC<Readonly<OrderSummaryProps>> = (props: Readonly<OrderSummaryProps>) => {
  const { t } = useTranslation()
  const {
    onPage,
    isLoading,
    hasShipping,
    cartTotals,
    cartInfo,
    localizations
  } = props
  const freeText = t("Common.Free");
  let culture = cartInfo?.currency ? currencyToLocale[cartInfo?.currency ?? DEFAULT_CURRENCY_CODE] : undefined
  let currency = cartInfo?.currency ?? ""

  const shippingCost = ((cartTotals?.shippingTotal ?? 0) + (cartTotals?.handlingTotal ?? 0))
  const isFreeShipping = shippingCost === 0;
  const price = !isFreeShipping ? <Price currency={currency} price={shippingCost.toString() ?? "0"} culture={culture} /> : freeText

  return (
    <div className="flex flex-col space-y-8 lg:space-y-14 pb-[20px] pt-3">
      <div className="flex flex-col space-y-4 md:space-y-8">
        <div className="text-grey-dark text-xl font-bold">
          {localizations?.cartSummaryTitle ?? "Order Summary"}
        </div>
        {/* Cart view */}
        {onPage ==='cart' &&
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between text-bold text-3xl">
                <span>{localizations?.productTotal ?? "Product Total"}</span>
                {isLoading ?
                  <div className="flex items-center text-right">
                    <LoadingSpinner className='w-[14px] h-[14px]' />
                  </div>
                :
                <span className='text-right'>
                  <Price currency={currency} price={cartTotals?.subTotal.toString() ?? "0"} culture={culture} />
                </span>
                }
            </div>
          </div>
        }

        {/* Checkout view */}
        {onPage ==='checkout' &&
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
              <span>{localizations?.productSubtotal ?? "Product Subtotal"}</span>
              <span className='text-right'>
              {isLoading ?
                  <div className="flex items-center text-right">
                    <LoadingSpinner className='w-[14px] h-[14px]' />
                  </div>
                :
                <Price currency={currency} price={cartTotals?.subTotal.toString() ?? "0"} culture={culture} />
              }
              </span>
            </div>
            <div className="flex flex-row justify-between">
              <span>{localizations?.productTaxes ?? "Product Taxes"}</span>
                <span className='text-right'>
                {isLoading ?
                  <div className="flex items-center text-right">
                    <LoadingSpinner className='w-[14px] h-[14px]' />
                  </div>
                :
                  (cartTotals?.taxTotal ?? 0) !== 0 && <Price currency={currency} price={cartTotals?.taxTotal.toString() ?? "0"} culture={culture} />
                }
                </span>
            </div>
            
            {hasShipping &&
              <div className="flex flex-row justify-between">
                <span>{localizations?.shippingCost ?? "Shipping Cost"}</span>
                <span className='text-right'>
                {!isLoading ?
                  price
                  :
                  <div className="flex items-center text-right">
                    <LoadingSpinner className='w-[14px] h-[14px]' />
                  </div>
                }
                  
                </span>
              </div>
            }
          
            <div className="flex flex-row justify-between mt-[20px]">
              <span className='text-3xl'>{hasShipping ? "Order Total" : localizations?.productTotal ?? "Estimated Order Total"}</span>
              <span className='text-right text-3xl'>
              {isLoading ?
                  <div className="flex items-center text-right">
                    <LoadingSpinner className='w-[14px] h-[14px]' />
                  </div>
                :
                <Price currency={currency} price={cartTotals?.total.toString() ?? "0"} culture={culture} />
              }
              </span>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
OrderSummary.defaultProps = {
  hasShipping: false,
}
export default OrderSummary
